import Vue from 'vue/dist/vue.esm'
import { snakeCaseKeys, defaultShipment } from './utils'
import sharedShipping from './shared_shipping'

Vue.component('shipping-form', {
  mixins: [sharedShipping],
  props: [
    'shipment', 'idx', 'nameprefix', 'workOrders', 'setWorkOrderId'
  ],
  data() {
    return {...defaultShipment,
      showParcel: false,
      loading: false,
      responseError: null,
      newShipment: this.shipment,
    }
  },
  template: `
  <div v-bind:class="{ shipment: true, loading: loading, deleted: deleted }">
    <input type="hidden" v-if="deleted" v-bind:name="formName('deleted')" value="true" />
    <div v-if="loading" class="lds-dual-ring"></div>
    <div class='row shipment_header' v-if="!purchased">
      <div class='columns'>
        <h4>{{ packageOrTracker }}</h4>
      </div>
      <div class="columns shrink">
        <button class="delete" v-on:click.prevent="markDeleted">×</button>
      </div>
    </div>
    <div v-if="responseError" class="form_error"><p><strong>{{ responseError }}</strong></p></div>
    <div v-if="setWorkOrderId"><input type="hidden" v-model="newShipment.workOrderId" v-bind:name="formName('work_order_id')" /></div>
    <div v-if="! hideWorkOrderField" class="input radio_buttons optional order_shipments_work_order_id inline work_order_id">
      <div class="radio"><label><input v-model="newShipment.workOrderId" autocomplete="off" class="radio_buttons optional" type="radio" value="" :name="formName('work_order_id')" />No work order</label></div>
      <div class="radio" v-for='item in workOrders' :key="item.id">
        <label><input v-model="newShipment.workOrderId" autocomplete="off" class="radio_buttons optional" type="radio" :value="item.id" :name="formName('work_order_id')" />{{ item.userName }}</label>
      </div>
    </div>
    <div class="input hidden order_shipments_id id"><input v-model="id" autocomplete="off" class="hidden" type="hidden" :name="formName('id')" /></div>
    <div class='row' v-if='!showParcel'>
      <div class='columns'><div class="input string optional order_shipments_tracking_code tracking_code"><label class="string optional">Tracking #</label><input v-model="trackingCode" autocomplete="off" class="string optional" type="text" :name="formName('tracking_code')" /></div></div>
      <div class='columns'><div class="input select optional order_shipments_carrier carrier"><label class="select optional">Carrier</label>
        <select v-model="carrier" autocomplete="off" class="select optional" :name="formName('carrier')">
          <option value="FedEx">FedEx</option>
          <option value="USPS">USPS</option>
          <option value="UPS">UPS</option>
        </select>
      </div></div>
    </div>
    <div class='shipment_info' v-if='showParcel'>
      <div class='label_info' v-if='purchased'>
        <div class='row'>
          <div class='columns'>
            <div>
              <strong>Label created {{labelCreatedAtFormatted}}</strong>
            </div>
            <div v-if="width">{{length}}x{{width}}x{{height}}", {{ weight }}lbs {{ labelRateFormatted }}</div>
            <div v-if="oneRatePackageType">One Rate {{oneRatePackageType}}, {{ weight }}lbs {{ labelRateFormatted }}</div>
            <div>{{ carrier }}, tracking <a class="icon_link icon icon_after" v-bind:href="trackingUrl" target="_blank">{{ trackingCode }} <i class="fas fa-external-link-alt"></i></a>
              <em v-if="shipment.latestStatusLine">{{ shipment.latestStatusLine }}</em>
            </div>
          </div>
          <div class='columns shrink'>
            <a class='icon icon_link' target='_blank' v-bind:href='labelPdfUrl'>
              <i class="fas fa-print"></i>Print Label
            </a>
          </div>
        </div>
      </div>
      <div class="shipment_info" v-if='!purchased'>
        <shipping-form-dimensions
          v-if="!$data.oneRate"
          v-model='newShipment'
          :nameprefix='nameprefix'
          :idx='idx'
          ></shipping-form-dimensions>
        <shipping-form-one-rate
          v-if="$data.oneRate"
          v-model='newShipment'
          :nameprefix='nameprefix'
          :idx='idx'
          ></shipping-form-one-rate>
      </div>
      <div class='row' v-if="!purchased">
        <div class='columns'>
          <button class='button small' v-bind:disabled='! validShipment' v-on:click.prevent='lookupRates'>Lookup Rates</button>
        </div>
      </div>
    </div>
    <div v-if="!purchased && hasTracking" class="shipping_status">
      <label class="label">Shipping status</label>
      {{ carrier }}, tracking <a class="icon_link icon icon_after" v-bind:href="trackingUrl" target="_blank">{{ trackingCode }} <i class="fas fa-external-link-alt"></i></a>&nbsp;
      <em>{{ shipment.latestStatusLine }}</em>
    </div>
    <div v-if="waitingForTrackingUrl" class="shipping_status">
      <p class="form_note">Tracker created, but we're waiting on the tracking URL. Wait a minute and refresh the page.</p>
    </div>
    <div>
      <div class='reveal shipping_rates' data-reveal ref='rates_modal'>
        <h2>Rates</h2>
        <button class="close-button" tabindex="0" aria-label="Close Alert" data-close>&times;</button>

        <div class='easy_post_errors' v-if='easyPostMessages &amp;&amp; easyPostMessages.length'>
          <p>We received an error message from the shipping service:</p>
          <ul>
            <li :key='index' is='error-messages-listing' v-bind='item' v-for='(item, index) in easyPostMessages'></li>
          </ul>
        </div>
        <table class='default'>
          <tbody>
            <tr :key='index' is='rates-listing' v-bind='item' v-for='(item, index) in rates' v-on:buy='buy'></tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

`,
  mounted() {
    for(var key in this.shipment) {
      this.$data[key] = this.shipment[key]
    }
    if(this.workOrderId === null) this.workOrderId = ''
    if(this.setWorkOrderId) this.newShipment.workOrderId = this.setWorkOrderId
    // For the reveal rates modal
    $(this.$el).foundation()
    this.ratesModal = this.$refs.rates_modal

    if(this.easyPostShipmentId) {
      this.showParcel = true
    }
    // Some users default to the create-label form
    else if(this.defaultToParcel && ! this.trackingCode) {
      this.showParcel = true
    }
  },
  computed: {
    validShipment() {
      return this.validDimensionShipment || this.validOneRateShipment
    },
    validDimensionShipment() {
      return this.newShipment.length && this.newShipment.height && this.newShipment.width && this.newShipment.weight
    },
    validOneRateShipment() {
      return this.newShipment.oneRatePackageType && this.newShipment.weight
    },
    purchased() {
      return !! this.labelPdfUrl
    },
    hideWorkOrderField() {
      return !! this.setWorkOrderId || (this.workOrders && this.workOrders.length === 0)
    },
    hasTracking() {
      return !! this.trackingUrl
    },
    waitingForTrackingUrl() {
      return (! this.hasTracking) && (this.easyPostTrackerId || (this.easyPostShipmentId && this.labelRateCents))
    },
    packageOrTracker() {
      if(this.showParcel) {
        if(this.purchased) {
          return 'Label Purchased'
        }
        return 'Create Label'
      }
      else {
        return 'Tracking number'
      }
    }
  },
  watch: {
    showParcel(newVal) {
      this.responseError = null
    },
  },
  methods: {
    formName(field) {
      return `${this.nameprefix}[${this.idx}][${field}]`
    },
    startLoading() {
      this.responseError = null
      this.loading = true
    },
    lookupRates() {
      this.easyPostMessages = null
      this.startLoading()
      jQuery.ajax({
        method: 'POST',
        url: '/shipments.json',
        data: { shipment: snakeCaseKeys(this.newShipment) },
        success: this.showRates.bind(this),
        error: this.failedRates.bind(this)
      })
    },
    markDeleted() {
      this.deleted = true
    },
  }
})
