import Vue from 'vue/dist/vue.esm'
require("expose-loader?$!jquery")
import truncate from 'lodash/truncate'
import startCase from 'lodash/startCase'
import { lineItemTypeDisplay } from './utils'

Vue.component('line-item-listing', {
  props: [
    'idx',
    'isCurrent',
    'id',
    '_destroy',
    'quantity',
    'type',
    'trackStyle',
    'totalPrice',
    'trackSubtotalPrice',
    'trackMarkupPercent',
    'projectNontrackSubtotalPrice',
    'markupPercent',
    'atsTrackKitTypeName',
    'atsTrackPartTypeName',
    'queue'
  ],
  template: `
  <tr v-on:click="$emit('openlineitem', idx)" v-bind:class="{ active: isCurrent, deleted: $props._destroy }">
    <td class="idx">{{idx + 1}}</td>
    <td class="type">{{ typeFormatted }}</td>
    <td class="quantity">{{quantity}}</td>
    <td class="style">{{ styleFormatted }}</td>
    <td class="cost">{{ totalPrice | toCurrency }}</td>
    <td v-if="projectQueue()" class="trackCost">{{ trackSubtotalPrice * (1 + trackMarkupPercent / 100) * quantity | toCurrency }}</td>
    <td v-if="projectQueue()" class="fabricCost">{{ projectNontrackSubtotalPrice * quantity | toCurrency }}</td>
  </tr>`,
  mounted() {
  },
  data() {
    return {
    }
  },
  watch: {
  },
  computed: {
    styleFormatted() {
      switch (this.type) {
        case 'LineItem::CurtainTrackValance':
        case 'LineItem::Track':
          if(this.trackStyle) {
            return startCase(this.trackStyle.replace('-', ' '))
          }
          break;
        case 'LineItem::ATSKit':
          return truncate(this.atsTrackKitTypeName, {length: 20})
          break
        case 'LineItem::ATSTrackPart':
          return truncate(this.atsTrackPartTypeName, {length: 20})
          break
      }
      return '—'
    },
    totalPriceFormatted() {
      return this.totalPrice ? `$${this.totalPrice}` : ''
    },
    typeFormatted() {
      return lineItemTypeDisplay[this.type]
    }
  },
  methods: {
    projectQueue() {
      return this.queue === "projects"
    }
  }
})
