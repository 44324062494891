import Vue from 'vue/dist/vue.esm'

Vue.component('otp-form', {
  template: `<input name="totp" v-model="totp" type="text" pattern="[0-9]{6}" placeholder="123456" maxlength="6" style="width: 6em; margin-top: 1em;" ref="totp" />`,
  data() {
    return {
      totp: ''
    }
  },
  mounted() {
    this.focusInput()
  },
  watch: {
    totp: function () {
      if (this.totp.length > 5) {
        $('form').submit()
      }
    }
  },
  methods: {
    focusInput() {
      this.$refs.totp.focus()
    }
  }
})
