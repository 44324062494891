import transform from 'lodash/transform'
import snakeCase from 'lodash/snakeCase'

export function snakeCaseKeys(data) {
  return transform(data, function(resultObject, value, key) {
    // ignore eg _delete
    if(key.indexOf('_') === 0) {
      resultObject[key] = value
    }
    else {
      resultObject[snakeCase(key)] = value
    }
  })
}
export function formatCurrency(num) {
  if(num) {
    return num.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
  }
}

// TODO erb would be nice.
// Using `puts LineItem::TYPE_DISPLAY.invert.to_json`
export const lineItemTypeDisplay = {
  "LineItem::CurtainTrackValance":"Curtain/Track/Valance",
  "LineItem::Track":"Track only",
  "LineItem::Rollershade":"Rollershade",
  "LineItem::Panel":"Panel",
  "LineItem::CubicleCurtain":"Cubicle Curtain",
  "LineItem::SnapCurtain":"Snap Curtain",
  "LineItem::Ripplefold":"Ripplefold",
  "LineItem::RipplefoldDraperyTrack":"Ripplefold Drapery Track",
  "LineItem::PinchPleat":"Pinch Pleat",
  "LineItem::Fabric":"Fabric Only",
  "LineItem::Valance":"Valance Only",
  "LineItem::SampleKit":"Sample Kit",
  "LineItem::ATSKit":"ATS Kit",
  "LineItem::ATSTrackPart":"ATS Track Part",
  "LineItem::InterlocKit":"Interloc Kit",
  "LineItem::InterlocPanel":"Interloc Panel",
  "LineItem::InterlocTrack":"Interloc Track",
  "LineItem::Strut":"Strut",
  "LineItem::Custom":"Custom"
}

export const defaultWorkOrder = {
  id: null,
  _destroy: false,
  status: 'open',
  troubleTicket: false,
  userId: null,
  date: '',
  notes: '',
}

export const defaultShipment = {
  trackingCode: '',
  trackingUrl: '',
  length: '',
  width: '',
  height: '',
  weight: '',
  carrier: 'FedEx',
  easyPostShipmentId: null,
  easyPostTrackerId: null,
  workOrderId: '',
  baseOrderId: '',
  labelPdfUrl: null,
  labelCreatedAtFormatted: null,
  labelRateFormatted: null,
  easyPostMessages: null,
  id: null,
  rates: [],
  easyPostMessages: '',
  defaultToParcel: false,
  showParcel: false,
  errors: null,
  labelRateCents: null,
  latestStatusLine: null,
  deleted: false,
  oneRatePackageType: ''
}

export const defaultLineItem = {
  id: null,
  _destroy: false,
  quantity: 1,
  type: 'LineItem::CurtainTrackValance',
  notes: '',
  trackStyle: 'style_1',
  screwType: 'self_tapping_screws',
  trackMaterial: 'aluminum_track',
  mountStyle: 'ceiling',
  carriers: '',
  flexibleEndCaps: '',
  endCaps: '',
  knockouts: '',
  splices: '',
  screws: '',
  totalCarriers: '',
  trackSizeA: '',
  trackSizeB: '',
  trackSizeC: '',
  trackSizeD: '',
  totalTrackSize: '',
  curtainLength: '',
  curtainWidth: '',
  panels: '1',
  fabricWidthsPerPanel: '',
  split: false,
  kissTheFloor: false,
  weightedHem: false,
  noFullness: false,
  velcro: false,
  velcroLength: '12',
  velcroToWall: true,
  tieback: false,
  valance: true,
  valanceDouble: false,
  valanceLength: '12',
  valanceWidth: '',
  valanceOnlyWidth: '',
  fabric: 'textron_black_black',
  cutLength: '',
  totalWidth: '',
  totalFabric: '',
  trackPrice: '',
  curtainPrice: '',
  valancePrice: '',
  velcroPrice: '',
  rollershadePrice: '',
  atsKitPrice: '',
  atsTrackPartPrice: '',
  overrideTrackPrice: '',
  overrideCurtainPrice: '',
  overrideValancePrice: '',
  overrideVelcroPrice: '',
  overrideRollershadePrice: '',
  overrideInterlocPanelPrice: '',
  overrideInterlocKitPrice: '',
  overrideStrutPrice: '',
  overrideATSKitPrice: '',
  overrideATSTrackPartPrice: '',
  doOverrideTrackPrice: false,
  doOverrideCurtainPrice: false,
  doOverrideValancePrice: false,
  doOverrideVelcroPrice: false,
  doOverrideRollershadePrice: false,
  doOverrideInterlocPanelPrice: false,
  doOverrideInterlocKitPrice: false,
  doOverrideStrutPrice: false,
  doOverrideATSKitPrice: false,
  doOverrideATSTrackPartPrice: false,
  totalPrice: '',
  rollerWidth: '',
  rollerHeight: '',
  rollerFabricStyle: '',
  rollerFabricColor: '',
  rollerFasciaColor: '',
  rollerSideChannelColor: '',
  rollerSillChannelColor: '',
  rollerControlType: 'manual',
  rollerRollType: 'standard_roll',
  rollerMountType: 'inside_mount',
  rollerDriveType: 'right',
  rollerWallMount: '',
  rollerEndcaps: '',
  rollerSpecialBattenSeamLocation: '',
  rollerMatchBracketType: '',
  rollerFabricWrappedExternalHembar: false,
  rollerRalroadAllShades: false,
  rollerPlasticBeadChain: false,
  rollerBackFascia: false,
  rollerDualShades: false,
  rollerAccessoriesOrder: '',
  fabricYards: '',
  atsTrackKitTypeId: null,
  atsTrackKitTypeName: '',
  atsTrackPartTypeId: null,
  atsTrackPartTypeName: '',
  customPrice: '',
  sampleFabrics: [],
  sampleTrackMaterials: [],
  sampleTrackColors: [],
  interlocLength: null,
  interlocColor: null,
  sampleInterlocPanels: [],
  strutTypeId: null,
  strutTypeName: '',
  trackColor: 'aluminum',
  interlocKitTypeId: null,
  interlocKitTypeName: '',
  interlocPanelCount: null,
  interlocKitPrice: null,
  interlocPanelPrice: null,
  interlocPackage: '',
  strutPrice: '',
  fabricCompany: '',
  fabricStyleName: '',
  fabricStyleUrl: '',
  fabricPerYardPrice: '',
  fabricWidth: null,
  fabricRepeatWidth: null,
  meshHeight: null,
  meshColor: 'white',
  hemHeight: 6,
  ceilingHeight: null,
  clearance: null,
  finishedLength: null,
  repetitions: null,
  cutLengthWithVerticalRepeat: null,
  ccMesh: null,
  totalWidths: null,
  railroaded: false,
  meshPerYardPrice: 8,
  fabricationPerWidthPrice: 36,
  markupPercent: 30,
  customMeshHeight: null,
  rrCombinedLength: null,
  rrFinishedFabricLength: null,
  fabricSubtotalPrice: '',
  meshSubtotalPrice: '',
  fabricationSubtotalPrice: '',
  draperySubtotalPrice: '',
  draperyFabric: null,
  snapCurtainSubtotalPrice: '',
  fullnessPercent: '',
  masterCarrierType: '',
  controlType: '',
  directionToOpen: '',
  draperyTrackColor: '',
  carrierType: '',
  returnType: '',
  rodType: '',
  controlLength: null,
  wallBracketType: '',
  wallBracketCount: '',
  trackSubtotalPrice: '',
  trackMarkupPercent: 30,
  queue: '',
  valanceMarkupPercent: 30,
  velcroMarkupPercent: 30,
  velcroSubtotalPrice: '',
  valanceSubtotalPrice: '',
  curtainSubtotalPrice: '',
  projectNontrackSubtotalPrice: '',
  splitDraw: '',
  pinSet: '',
  pinchPleatLeftReturn: 3.5,
  pinchPleatRightReturn: 3.5,
  pinchPleatRightOverlap: 2.5,
  pinchPleatLeftOverlap: 2.5,
  pinchPleatTopHem: 8,
  pinchPleatBottomHem: 8,
  fabricCalc: '',
  pleatSpacing: 4
}
