import Vue from 'vue/dist/vue.esm'
require("expose-loader?$!jquery")
import handleEscape from './handle_escape'

Vue.component('email-form', {
  mixins: [handleEscape],
  props: [
    'email',
  ],
  mounted() {
    this.updateDataFromProps()
    this.initialBody = this.body
    this.watchKeys()
  },
  data() {
    return {
      emailId: null,
      kind: '',
      body: '',
      subject: '',
      toAddress: '',
      sentAt: '',
      initialBody: ''
    }
  },
  watch: {

  },
  computed: {
    readOnly() {
      return !! this.emailId
    }
  },
  methods: {
    updateDataFromProps() {
      for(var key in this.email) {
        this[key] = this.email[key]
      }
    },
    save() {
      this.$emit('save', this.$data)
    },
    close() {
      this.removeKeyListener()
      this.$emit('close')
    },
    sendEmail() {
      this.removeKeyListener()
      this.$emit('save', this.$data)
    }
  }
})
