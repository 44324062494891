import Vue from 'vue/dist/vue.esm'
require("expose-loader?$!jquery")

Vue.component('error-messages-listing', {
  props: [
   'carrier', 'type', 'message'
  ],
  template: `
  <li>
    <strong>{{ carrier }}</strong> — <em>{{ message }}</em>
  </li>`,
  mounted() {
  },
  data() {
    return {
    }
  },
  methods: {
  }
})
