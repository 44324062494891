import Vue from 'vue/dist/vue.esm'

const sharedShipping = {
  methods: {
    showRates(response) {
      this.loading = false
      this.id = response.shipment.id
      this.easyPostShipmentId = response.easyPostShipmentId
      this.rates = response.shipment.rates
      this.easyPostMessages = response.shipment.easyPostMessages
      $(this.ratesModal).foundation('open')
    },
    successfulPurchase(response) {
      this.loading = false
      for(var key in response.shipment) {
        this.$data[key] = response.shipment[key]
      }
    },
    buy(rate_id) {
      $(this.ratesModal).foundation('close')
      this.loading = true
      jQuery.ajax({
        method: 'POST',
        url: `/shipments/${this.id}/buy`,
        data: {rate_id: rate_id},
        success: this.successfulPurchase.bind(this),
        error: this.failedPurchase.bind(this)
      })
    },
    failedRates(response) {
      this.loading = false
      this.responseError = response.responseJSON.errors || "There was an error fetching rates; try again later or contact suppport."
    },
    failedPurchase(response) {
      this.loading = false
      this.responseError = response.responseJSON.errors || "There was an error purchasing the label; try again later or contact suppport."
    },
  }
}

export default sharedShipping
