import Vue from 'vue/dist/vue.esm'
require("expose-loader?$!jquery")
import handleEscape from './handle_escape'
import without from 'lodash/without'
import { snakeCaseKeys, defaultWorkOrder } from './utils'

Vue.component('work-order-form', {
  mixins: [handleEscape],
  props: [
    'workOrder', 'idx'
  ],
  mounted() {
    this.updateDataFromProps()
    // vue-trix is mounted before the hidden field has a value; set it manually
    this.watchKeys()
    if(this.userId == null) {
      let select = this.$refs.user_select
      this.userId = select.children[0].value
    }
  },
  data() {
    return {...defaultWorkOrder,
      initialNotes: '',
      handleChanges: true,
      dataChanged: false,
      sendingEmail: false,
      emailResult: null,
      workerComments: '',
      workerCommentsUpdatedAt: '',
    }
  },
  watch: {
    workOrder: {
      handler() {
        this.updateDataFromProps()
      },
      deep: true
    },
    $data: {
      handler(newData) {
        // Prevent extraneous price-fetching
        if(this.handleChanges) {
          this.dataChanged = true
          this.save()
        }
      },
      deep: true
    }
  },
  computed: {
    workOrderLink() {
      return `/work_orders/${this.id}`
    },
    workOrderKind() {
      return this.troubleTicket ? 'Trouble Ticket' : 'Work Order'
    },
    isDeleted() {
      return this.$data._destroy === true
    }
  },
  methods: {
    updateDataFromProps() {
      this.handleChanges = false
      for(var key in this.workOrder) {
        // Using this.$data to handle _destroy,
        // which is not proxied
        this.$data[key] = this.workOrder[key]
      }
      this.initialNotes = this.notes
      this.handleChangesNow()
    },
    save() {
      this.$emit('save', this.$data)
    },
    close() {
      this.removeKeyListener()
      this.$emit('close')
    },
    updateNotes(str) {
      this.notes = str
    },
    deleteItem() {
      this.$data._destroy = true
      this.save()
      this.close()
    },
    undelete() {
      this.$data._destroy = false
      this.save()
    },
    resendEmail() {
      this.sendingEmail = true
      jQuery.ajax({
        method: 'PUT',
        data: {work_order: snakeCaseKeys(this.$data)},
        url: `/work_orders/${this.id}/resend_email`,
        success: this.sentEmail.bind(this),
        error: this.failedEmail.bind(this)
      })
    },
    sentEmail() {
      this.sendingEmail = false
      this.emailResult = 'Sent!'
    },
    failedEmail() {
      this.sendingEmail = false
      alert('There was a problem sending the email... check with an admin.')
    }
  }
})
