import Vue from 'vue/dist/vue.esm'

Vue.component('salesman-filter', {
  data() {
    return {
      salesman: 'all'
    }
  },
  props: [ 'currentSalesman', 'scope' ],
  mounted() {
    this.salesman = this.currentSalesman
  },
  watch: {
    salesman(newVal) {
      if (newVal != this.currentSalesman) {
        var newUrl = `${window.location.pathname}?salesman=${newVal}`
        if (this.scope) {
          newUrl += `&s=${this.scope}`
        }
        window.location = newUrl
      }
    }
  },
})
