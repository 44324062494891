import Vue from 'vue/dist/vue.esm'

Vue.component('shipping-form-one-rate', {
  props: [
    'nameprefix', 'idx', 'value'
  ],
  methods: {
    formName(field) {
      return `${this.nameprefix}[${this.idx}][${field}]`
    },
    updateValue(key, value) {
      this.$emit("input", { ...this.value, [key]: value });
    }
  },
  template: `
    <div class='shipping-form-one-rate'>
      <div class='row'>
        <div class='columns'>
          <div class="input float optional order_shipments_one_rate_package_type one_rate_package_type">
            <label class="float optional">One Rate Package Type</label>
            <select :value="value.oneRatePackageType" @input="updateValue('oneRatePackageType', $event.target.value)" autocomplete="off" class="select optional" :name="formName('one_rate_package_type')">
              <option value="FedExEnvelope">FedExEnvelope</option>
              <option value="FedExPak">FedExPak</option>
            </select>
          </div>
        </div>
        <div class='columns'>
          <div class="input float optional order_shipments_weight weight">
            <label class="float optional">Weight (lbs)</label>
            <input :value="value.weight" @input="updateValue('weight', $event.target.value)" autocomplete="off" class="numeric float optional" type="number" step="any" :name="formName('weight')" />
          </div>
        </div>
      </div>
    </div>
  `
})
