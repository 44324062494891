import Vue from 'vue/dist/vue.esm'

Vue.component('customer-form', {
  props: [
  ],
  mounted() {
    var passedData = $(this.$el).data()
    for(var key in passedData) {
      this[key] = passedData[key]
    }
    Vue.nextTick( () => {
      this.pageLoaded = true
    })
  },
  data() {
    return {
      pageLoaded: false,
      name: '',
      shippingSameAsBilling: true,
      billingCountry: '',
      shippingCountry: '',
      fromQueue: '',
    }
  },
  computed: {
    displayName() {
      return this.name || 'New Customer'
    }
  },
  methods: {
    showShipping() {
      return !this.shippingSameAsBilling
    }
  }
})
