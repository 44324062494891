import Vue from 'vue/dist/vue.esm'
require("expose-loader?$!jquery")

Vue.component('public-order-form', {
  props: [
  ],
  mounted() {
    var passedData = $(this.$el).data()
    for(var key in passedData) {
      this[key] = passedData[key]
    }
  },
  data() {
    return {
      billingCountry: '',
      shippingCountry: '',
      showButtons: false,
      showShipping: false,
      showBilling: false
    }
  },
  computed: {

  }
})