import Vue from 'vue/dist/vue.esm'
require("expose-loader?$!jquery")
import startCase from 'lodash/startCase'

Vue.component('work-order-listing', {
  props: [
    'idx',
    'isCurrent',
    'userName',
    'userId',
    'status',
    'date',
    'troubleTicket',
    'workUsers', // Just to display users in listing
    'id', '_destroy',
  ],
  template: `
  <tr v-on:click="$emit('openworkorder', idx)" v-bind:class="{ active: isCurrent, deleted: $props._destroy, trouble_ticket: troubleTicket }">
    <td class="kind"><i v-if="troubleTicket" class="fas fa-exclamation-triangle"></i></td>
    <td class="date">{{ date }}</td>
    <td class="assigned">{{ userNameDisplay }}</td>
    <td class="status">{{ statusDisplay }}</td>
  </tr>`,
  mounted() {
  },
  data() {
    return {
    }
  },
  watch: {

  },
  computed: {

    userNameDisplay() {
      if(this.userName) {
        return this.userName
      }
      else {
        return this.workUsers[this.userId]
      }
    },
    // userNames() {
    //   if(this.userIds.length > 0) {
    //     return this.userIds.map( (id) => this.workUsers[id] ).sort().join(', ')
    //   }
    //   else {
    //     return '—'
    //   }
    // },
    statusDisplay() {
      return startCase(this.status)
    }
  },
  methods: {
  }
})
