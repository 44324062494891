import Vue from 'vue/dist/vue.esm'
import { defaultShipment } from './utils'

Vue.component('worker-work-order-form', {
  props: [
  ],
  data() {
    return {
      workerComments: '',
      shipments: [],
      baseOrderId: null,
      defaultLabel: false,
      troubleTicket: false,
      notes: ""
    }
  },
  mounted() {
    var passedData = $(this.$el).data('workOrderJson')
    for(var key in passedData) {
      this[key] = passedData[key]
    }
    if(this.shipments.length === 0) {
      let newShipment = this.getNewShipment()
      if(this.defaultLabel) {
        newShipment.showParcel = true
      }
      this.shipments.push(newShipment)
    }
  },
  watch: {

  },
  methods: {
    // Shipments
    // =================================
    getNewShipment() {
      let newShipment = Object. assign({}, defaultShipment)
      newShipment.baseOrderId = this.baseOrderId
      return newShipment
    },
    addLabel() {
      let newShipment = this.getNewShipment()
      newShipment.showParcel = true
      this.shipments.push(newShipment)
    },
    addOneRateLabel() {
      let newShipment = this.getNewShipment()
      newShipment.showParcel = true
      newShipment.oneRate = true
      newShipment.oneRatePackageType = 'FedExEnvelope'
      this.shipments.push(newShipment)
    },
    addTracker() {
      let newShipment = this.getNewShipment()
      this.shipments.push(newShipment)
    },
    updateComments(str) {
      this.comments = str
    },
  }
})
