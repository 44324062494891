import Vue from 'vue/dist/vue.esm'

Vue.component('trix-vue', {
  props: [
    'inputId',
    'placeholder',
    'initialHtml',
    'label'
  ],
  template: `
  <div class="input" v-bind:class="inputId">
    <label>{{ label }}</label>
    <trix-editor v-bind:id="trixId" v-bind:input="inputId" v-bind:placeholder="placeholder" @trix-change="textChange" ref="trix">
    </trix-editor>
  </div>
  `,
  mounted() {
  },
  data() {
    return {
    }
  },
  watch: {
    // initialHtml only changes once, when parent form sets up its data
    // from props; otherwise the hidden field doesn't have any data
    initialHtml(val) {
      this.$refs.trix.editor.loadHTML(val)
    }
  },
  computed: {
    trixId() {
      return `trix_${this.inputId}`
    }
  },
  methods: {
    textChange(event) {
      this.$emit('input', event.srcElement ? event.srcElement.value : event.target.value)
    }
  }
})