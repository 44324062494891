// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
// require("jquery")

require("expose-loader?$!jquery")

import Foundation from 'foundation-sites';
require('modules/attachment_form')
require('modules/customer_form')
require('modules/email_form')
require('modules/initial_work_order_form')
require('modules/label_maker')
require('modules/line_item_form')
require('modules/line_item_listing')
require('modules/error_messages_listing')
require('modules/public_order_form')
require('modules/order_form')
require('modules/otp_form')
require('modules/rates_listing')
require('modules/salesman_filter')
require('modules/search')
require('modules/shipping_form')
require('modules/shipping_form_dimensions')
require('modules/shipping_form_one_rate')
require('modules/selectize_no_results')
require('modules/stripe_form')
require('modules/trix_vue')
require('modules/user_form')
require('modules/work_order_form')
require('modules/work_order_form')
require('modules/work_order_form')
require('modules/work_order_listing')
require('modules/worker_work_order_form')


window.ADS = {}

import { pageSetup } from 'modules/page_setup';
Foundation.addToJquery(jQuery)
$(document).ready(pageSetup)
require("trix")
require("@rails/actiontext")
