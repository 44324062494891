import Vue from 'vue/dist/vue.esm'

Vue.component('attachment-form', {
  props: [
  ],
  mounted() {

  },
  data() {
    return {
      deleted: false
    }
  },
  computed: {
    deletedClass() {
      return this.deleted ? 'deleted' : null
    }
  },
  methods: {
    deleteAttachment() {
      var field = this.$refs.delete_field
      if(this.deleted) {
        this.deleted = false
        field.value = 'false'
      }
      else {
        this.deleted = true
        field.value = 'true'
      }
    }
  }
})