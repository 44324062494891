import Vue from 'vue/dist/vue.esm'
require("expose-loader?$!jquery")
import Rails from '@rails/ujs'
import { formatCurrency } from './utils'

const buttonSelector = '[data-behavior=pay]'

Vue.component('stripe-form', {
  props: [
    'stripeKey',
    'totalCalculated',
    'allowPartialPayment',
    'totalPaid'
  ],
  mounted() {
    this.form = this.$refs.paymentform
    this.setupStripe()
    this.setChargeAmount(this.totalCalculated)
  },
  data() {
    return {
      cardErrors: null,
      stripeToken: null,
      chargeAmount: 0
    }
  },
  watch: {
    totalCalculated(val) {
      this.setChargeAmount(val)
    }
  },
  methods: {
    setChargeAmount(val) {
      // Don't run on public page, where totalPaid is not passed
      if(! this.totalPaid) {return}

      // if total paid is
      if(this.totalPaid > 0) {
        this.chargeAmount = formatCurrency(this.totalCalculated - this.totalPaid)
      }
      else {
        this.chargeAmount = formatCurrency(this.totalCalculated * 0.5)
      }
    },
    setupStripe() {
      this.stripe = window.Stripe(this.stripeKey)
      let elements = this.stripe.elements({
        fonts: [
          {
            cssSrc: 'https://use.typekit.net/wmy1rmj.css',
          }
        ]

      })
      const style = {
        style: {
          base: {
            iconColor: '#1779ba',
            color: '#281c0f',
            backgroundColor: 'white',
            fontWeight: 500,
            fontFamily: 'acumin-pro, Helvetica, Roboto, Arial, sans-serif',
            fontSize: '18px',
            fontSmoothing: 'antialiased',
            ':-webkit-autofill': {
              color: '#fce883',
            },
            '::placeholder': {
              color: '#cacaca',
            },
          },
          invalid: {
            iconColor: '#cc4b37',
            color: '#cc4b37',
          }
        }
      }
      this.cardNumber = elements.create('cardNumber', style)
      this.cardNumber.mount('#card-number')
      elements.create('cardExpiry', style).mount('#card-expiry')
      elements.create('cardCvc', style).mount('#card-cvc')
      this.cardNumber.on('change', function(event) {
        if (event.complete) {
          $(buttonSelector).attr('disabled', false)
        } else {
          $(buttonSelector).attr('disabled', true)
        }
      })
      this.cardNumber.focus() // does not seem to work
    },
    submitForm(event) {
      event.preventDefault()
      let target = event.target
      Rails.disableElement(target)
      let vue = this
      this.stripe.createToken(this.cardNumber).then(function(result) {
        if (result.error) {
          vue.cardErrors = result.error.message
          $(buttonSelector).attr('data-disabled-with', false)
          $(buttonSelector).attr('disabled', false)
          $(buttonSelector)[0].innerText = 'Submit Payment'
          Rails.enableElement(target)
        } else {
          vue.cardErrors = null
          vue.handleToken(result.token)
        }
      })
    },
    handleToken(token) {
      this.stripeToken = token.id
      Vue.nextTick( () => {
        this.$emit('submit-stripe')
        if(this.form) {
          this.form.submit()
        }
      })
    }
  }
})
