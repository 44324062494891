import Vue from 'vue/dist/vue.esm'

Vue.component('user-form', {
  data() {
    return {
      accessLevel: 'standard',
      queueAccess: 'product'
    }
  },
  props: [ 'currentAccessLevel' ],
  mounted() {
    this.accessLevel  = this.currentAccessLevel
  }
})
