import Vue from 'vue/dist/vue.esm'
require("expose-loader?$!jquery")

Vue.component('rates-listing', {
  props: [
    'id', 'object', 'service', 'carrier', 'rate', 'delivery_days'
  ],
  template: `
  <tr>
    <td class="carrier">{{ carrier }} {{ service }}</td>
    <td class="days"><span v-show="delivery_days">{{ delivery_days }} days</span></td>
    <td class="rate">{{ rate | toCurrency }}</td>
    <td class="buy"><button class="button tiny" v-on:click.prevent="$emit('buy', id)">Select Rate</button></td>
  </tr>`,
  mounted() {
  },
  data() {
    return {
    }
  },
  methods: {
  }
})
