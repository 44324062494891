import Vue from 'vue/dist/vue.esm'

const customerSelectize = {
  methods: {
    configCustomerSelect(el, selectedCustomer) {
      let customerData = el.data('customers')
      this.customerSelectize = el.selectize({
        closeAfterSelect: true,
        plugins:  ['no_results'],
        placeholder: 'Select a customer',
        valueField: 'id',
        labelField: 'name',
        searchField: 'name',
        options: customerData,
        items: [selectedCustomer],
        onChange: this.setCustomer,
        render: {
          item(item, escape) {
                return '<div>' +
                    (item.name ? '<span class="name">' + escape(item.name) + '</span>' : '') +
                    (item.city_state ? '<span class="location">' + escape(item.city_state) + '</span>' : '') +
                '</div>';
          },
          option(item, escape) {
                var label = item.name || item.city_state;
                var caption = item.name ? item.city_state : null;
                return '<div>' +
                    '<span class="label">' + escape(label) + '</span>' +
                    (caption ? '<span class="caption">' + escape(caption) + '</span>' : '') +
                '</div>';
            }
        },
      })[0].selectize
    }
  }
}

export default customerSelectize
