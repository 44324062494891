import Vue from 'vue/dist/vue.esm'
require("expose-loader?$!jquery")

export const pageSetup = () => {
  Vue.config.ignoredElements = ['trix-editor', 'trix-toolbar', 'sidebar']
  window.ADS.mainVue = new Vue({
    el: '#app',
    mounted() {
      window.setTimeout( () => jQuery('input[autofocus], textarea[autofocus]').focus(), 10)
    }
  })

  // Set up Foundation after vue is instantiated
  $(document).foundation()
  if($('.flash_wrap').length && !$('body.public_orders-show').length) {
    window.setTimeout( () => $('.flash_wrap .close-button').click(), 3000)
  }
  $('table.linked tbody').on('click', 'tr', (e) => {
    if(e.target.nodeName.toUpperCase() == 'A') {
      return true
    }
    else {
      const href = $(e.target).closest('tr').addClass('clicked').find('a.clk').attr('href')
      if(href) {
        // Turbolinks.visit(href)
        window.location = href
      }
    }
  })
}