import Vue from 'vue/dist/vue.esm'
import { snakeCaseKeys, defaultShipment } from './utils'
import customerSelectize from './customer_selectize'
import sharedShipping from './shared_shipping'
require("selectize/dist/js/standalone/selectize.js")

Vue.component('label-maker', {
  mixins: [customerSelectize, sharedShipping],
  data() {
    return {
      name: null,
      addressLine1: null,
      addressLine2: null,
      city: null,
      state: null,
      zip: null,
      country: null,
      phone: null,
      height: null,
      length: null,
      weight: null,
      width: null,
      oneRatePackageType: null,
      easyPostMessages: null,
      rates: null,
      responseError: null,
      loading: false,
      labelPdfUrl: null,
      labelCreatedAtFormatted: null,
      labelRateFormatted: null,
      carrier: 'FedEx', // matches db default. probably some slicker way to address this.
      trackingUrl: null,
      trackingCode: null,
      shipment: null
    }
  },
  mounted() {
    $(this.$el).foundation()
    this.ratesModal = this.$refs.rates_modal

    let $customerSelect = $('#customer_id')
    this.configCustomerSelect($customerSelect, null)
  },
  methods: {
    newCustomer() {
      // No error handling, because what could go wrong?
      jQuery.ajax({
        method: 'POST',
        url: '/customers.json',
        data: { customer: {
          name: this.name,
          billing_address_1: this.addressLine1,
          billing_address_2: this.addressLine2,
          billing_city: this.city,
          billing_state: this.state,
          billing_zip: this.zip,
          billing_country: this.country,
          billing_phone: this.phone,
          shipping_same_as_billing: 1
          }
        },
        success: this.customerSuccess.bind(this)
      })

    },
    lookupRates() {
      const valid = document.getElementById('new_mailing_label').reportValidity()
      if (!valid) return
      this.responseError = null
      this.easyPostMessages = null
      this.loading = true
      jQuery.ajax({
        method: 'POST',
        url: '/shipments/label_maker.json',
        data: {shipment: snakeCaseKeys(this.$data)},
        success: this.showRates.bind(this),
        error: this.failedRates.bind(this)
      })
    },
    customerSuccess(event) {
      window.location.href = event.location
    },
    setCustomer(customer_id) {
      jQuery.ajax({
        method: 'GET',
        url: `/customers/${customer_id}.json`,
        success: this.updateFromCustomer.bind(this),
      })
    },
    updateFromCustomer(response) {
      this.name = response.name
      this.addressLine1 = response.shipping_address_1
      this.addressLine2 = response.shipping_address_2
      this.city = response.shipping_city
      this.state = response.shipping_state
      this.zip = response.shipping_zip
      this.country = response.shipping_country
      this.phone = response.shipping_phone
    },
    resetPackageFields() {
      this.length = this.width = this.height = this.weight = this.labelPdfUrl = this.oneRatePackageType = null
      Vue.nextTick( () => {
        this.configCustomerSelect($('#customer_id'), null)
      })
    },
    resetAllFields() {
      this.name = this.addressLine1 = this.addressLine2 = this.city = this.state = this.zip = this.country = this.phone = null
      this.resetPackageFields()
    },
  }
})
