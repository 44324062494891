import Vue from 'vue/dist/vue.esm'
require("expose-loader?$!jquery")
import handleEscape from './handle_escape'
import without from 'lodash/without'
import { snakeCaseKeys } from './utils'

Vue.component('initial-work-order-form', {
  mixins: [handleEscape],
  props: [
    'initialOrders'
  ],
  mounted() {
    this.updateDataFromProps()
    // vue-trix is mounted before the hidden field has a value; set it manually
    this.initialNotes = ''
    this.watchKeys()
  },
  data() {
    return {userIds: [], notes: '', initialNotes: ''}
  },
  watch: {
  },
  computed: {
    hasAssignments() {
      return this.userIds.length > 0
    }
  },
  methods: {
    updateDataFromProps() {
      for(var key in this.initialOrders) {
        this[key] = this.initialOrders[key]
      }
    },
    sendOrders() {
      this.$emit('save', this.$data)
    },
    close() {
      this.removeKeyListener()
      this.$emit('close')
    },
    updateNotes(str) {
      this.notes = str
    }
  }
})