import Vue from 'vue/dist/vue.esm'

Vue.component('shipping-form-dimensions', {
  props: [
    'nameprefix', 'idx', 'value'
  ],
  methods: {
    formName(field) {
      return `${this.nameprefix}[${this.idx}][${field}]`
    },
    updateValue(key, value) {
      this.$emit("input", { ...this.value, [key]: value });
    }
  },
  template: `
    <div class='shipping-form-dimensions'>
      <div class='row'>
        <div class='columns'>
          <div class="input float optional order_shipments_length length">
            <label class="float optional">Length</label>
            <input ref="length_field" :value="value.length" @input="updateValue('length', $event.target.value)" autocomplete="off" class="numeric float optional" type="number" step="any" :name="formName('length')" />
          </div>
        </div>
        <div class='columns'>
          <div class="input float optional order_shipments_width width">
            <label class="float optional">Width</label>
            <input :value="value.width" @input="updateValue('width', $event.target.value)" autocomplete="off" class="numeric float optional" type="number" step="any" :name="formName('width')" />
          </div>
        </div>
      </div>
      <div class='row'>
        <div class='columns'>
          <div class="input float optional order_shipments_height height">
            <label class="float optional">Height</label>
            <input :value="value.height" @input="updateValue('height', $event.target.value)" autocomplete="off" class="numeric float optional" type="number" step="any" :name="formName('height')" />
          </div>
        </div>
        <div class='columns'>
          <div class="input float optional order_shipments_weight weight">
            <label class="float optional">Weight (lbs)</label>
            <input :value="value.weight" @input="updateValue('weight', $event.target.value)" autocomplete="off" class="numeric float optional" type="number" step="any" :name="formName('weight')" />
          </div>
        </div>
      </div>
    </div>
  `
})
