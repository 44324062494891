import Vue from 'vue/dist/vue.esm'
import VueSimpleSuggest from 'vue-simple-suggest'
import urls from './urls'

Vue.component('global-search', {
  props: [

  ],
  components: {
    VueSimpleSuggest
  },
  template: `
  <div id="search">
    <vue-simple-suggest
        v-model="chosen"
        :min-length="3"
        :list="getSuggestionList"
        :debounce="200"
        value-attribute="id"
        display-attribute="display_order_number"
        placeholder="Search…"
        @select="onSuggestSelect"
        accesskey="s"
      >
    <div slot="suggestion-item" slot-scope="{ suggestion, query }">
        <strong class="order_number">{{ suggestion.display_order_number }}</strong>
        <span class="status status_tag" v-bind:class="'status_' + suggestion.status">{{ suggestion.status_formatted }}</span>
        <span class="date">{{ suggestion.display_date_smart_s }}</span>
        <span v-if="suggestion.customer" class="customer">{{ suggestion.customer.name }}</span>
    </div>
    </vue-simple-suggest>
  </div>`,
  mounted() {
  },

  data() {
    return {
      chosen: ''
    }
  },
  watch: {

  },
  methods: {

    getSuggestionList(inputValue) {
      const url = `${urls.search}?term=${inputValue}`
      return fetch(url, { method: 'GET' })
        .then(response => response.json())
        .then(json => json)
    },
    onSuggestSelect(order) {
      const kind = order.type.toLowerCase()
      window.location.href = `/${kind}s/${order.id}/edit`
    }
  }
})
