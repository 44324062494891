import Vue from 'vue/dist/vue.esm'

const handleEscape = {
  methods: {
    watchKeys() {
      document.addEventListener('keydown', this.onKey)
    },
    removeKeyListener() {
      document.removeEventListener('keydown', this.onKey)
    },
    beforeDestroy() {
      this.removeKeyListener()
    },
    onKey(e) {
      if(e.key === "Escape") {
        this.close()
      }
    },
    // Setting this directly causes changes to be handled with recently-changed
    // updates; doing it on next tick prevents double-price-checking
    handleChangesNow() {
      Vue.nextTick( () => {
        this.handleChanges = true
      })
    },
  }
}

export default handleEscape